body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #fafafa;
}
.ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0,0,0,.85);
    line-height: 64px;
    background: #fff !important;
}
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #333;
    font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrap {
  max-width: 1000px;
  margin: 24px auto;
}

ol li {
  margin-bottom: 6px;
}

.ant-table-wrapper .ant-table-thead >tr>th {
  text-align: center
}

.inline_form .ant-form-item {
  display: inline-block;
  margin-bottom: 0;
}

.custom-form .ant-typography {
  line-height: 32px;
}

.ant-table-wrapper .ant-table.ant-table-small {
  border: 1px solid;
  border-radius: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-begin {
  padding-left: 32px;
}

tr {
  vertical-align: baseline;
}

.ant-space {
  display: flex;
}

.table-footer {
  text-align: right;
}

.ant-btn-primary {
    color: #fff !important;
    border-color: #1da57a !important;
    background: #1da57a !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%) !important;
}



.ticket_card {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  filter: drop-shadow(2px 5px 5px rgba(0, 0, 0, 0.15));
}
.ticket_card *::before, .ticket_card *::after {
  box-sizing: unset !important;
}
.ticket_card__details {
  flex: 3;
  background-color: #fff;
  min-height: 150px;
  padding: 1rem;
  border-radius: 5px 0 0 5px;
}
.ticket_card__details .ticket_card__title {
  margin-top: 0;
}
.ticket_card__details ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ticket_card__details ul li {
  margin: 0.7rem 0;
}
.ticket_card__rip {
  position: relative;
  background-color: #fff;
  background-image: linear-gradient(90deg, #fff 49%, #02d667 50%);
  margin: 10px 0;
  width: 20px;
}
.ticket_card__rip::before, .ticket_card__rip::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 5px solid transparent;
  border-top-color: #fff;
  left: 50%;
  transform: translateX(-50%) rotate(135deg);
  border-radius: 50%;
}
.ticket_card__rip::before {
  top: -25px;
  border-top-color: #02d667;
  border-right-color: #fff;
}
.ticket_card__rip::after {
  border-right-color: #02d667;
  border-top-color: #fff;
  bottom: -25px;
  transform: translateX(-50%) rotate(-45deg);
}
.ticket_card__price {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 2;
  background-color: #02d667;
  min-height: 150px;
  padding: 1rem;
  border-radius: 0 5px 5px 0;
  color: #fff;
}

.ticket_card__price .heading {
  font-size: 1rem;
  margin-bottom: 0.6rem;
  color: #ffcdde;
  margin-left: -5px;
}
.ticket_card__price .price {
  font-size: 2rem;
  font-weight: bold;
  margin-left: -5px;
}

.wallet_card {
    background-size: cover;
    position: relative;
    z-index: 1;
}

.init_webcam video {
  transform: scaleX(-1);
}

