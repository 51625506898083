.tv-wellness {
  background-image: url('../../../../public/img/liff/event/wellness/bg.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: #025637;
  position: relative;
}

.tv-wellness-survey {
  background-image: url('../../../../public/img/liff/event/wellness/bg_survey.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: #025637;
  position: relative;
}
.tv-wellness-survey-qr{
    position: relative;
    top: 920px;
    align-items: center;
    text-align: center;
}
.tv-wellness-survey-qr canvas{
  margin:auto !important;
}

.tv-wellness-time{
    position: relative;
    top: 565px;
    font-size: 60px;
    font-weight: 900;
    padding-left: 270px;
}
.tv-wellness-total{
    position: absolute;
    top: 565px;
    font-size: 60px;
    font-weight: 900;
    padding-left: 800px;
}

.tv-wellness-banner{background-color: #ffffff;border-radius: 30px; color:#46A758;font-size:80px;padding:30px }
.tv-wellness-qr{
    position: relative;
    top: 950px;
    align-items: center;
    text-align: center;
}
.tv-wellness-qr canvas{
  margin:auto !important;
}
.tv-wellness-remark{
    position: relative;
    top: 1050px;
}